@media (max-width: 1200px){
#navBar .top-bar .menu > li {
    padding-left: 20px;
}
}


@media (max-width: 1024px){

#navBar .topnav .title-bar .menu-icon:focus {
    outline: none;
}	
.light-off-menu .off-menu-close {
   padding: 13px 15px;
   }
.light-off-menu .off-social a{
	width: 26px;
    height: 26px;
    line-height: 26px;
    margin-right: 4px;
    background: inherit;
    text-align: center;
    border-radius: 50% !important;
    border: 1px solid #444851;
}
.light-off-menu .off-social a i {
    color: #444851;
}
.light-off-menu .off-social h6 {
    margin-bottom: 10px;
}
.light-off-menu .off-menu li a {
    padding: 12px 0 12px 0;
}
.light-off-menu .off-menu {
    margin-bottom: 0px;
}
.is-drilldown{
	min-height: 345px !important;
}
.light-off-menu.is-open{
    box-shadow: 0px 3px 6px 2px rgba(0,0,0,0.5);
    border: none;
}
.light-off-menu.is-open .off-menu-close{
	 border-right: 1px solid #C50705;
    border-bottom: none;
}
.light-off-menu .off-menu {
    padding: 0 12px;
}
.current-show-left , .program-wrap-left {
    margin-bottom: 30px;
}
.program-wrap .program-wrap-right {
    padding-left: 0.625rem;
}
}

@media (max-width: 767px){
.sticky.topnav , .sticky.topnav .title-bar{
    background: #C50705 !important;
}
#navBar .topnav .title-bar .menu-icon::after {
    background: #ffffff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
}
#navBar .topnav .title-bar .menu-icon:focus {
    outline: none;
}	
}

@media (min-width: 640px) and (max-width: 992px) {

}

@media (max-width: 639px){

.current-show .current-show-left img {
    margin-bottom: 30px;
}
section.latest-items .row {
    padding: 0 10px;
}
section {
    margin-bottom: 40px;
}
}
@media (max-width: 450px){
.latest-items-left .li-left .li-data p {
    font-size: 15px;
}
}