

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "RTS1Police", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "RTS1Police", "Courier New",
    monospace;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #8a0000;
  border-color: #8a0000;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #8a0000;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #8a0000;
  border-color: #8a0000;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #8a0000;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.my-1 {
  margin: 1rem 0;
}
