

.block-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  color: #c50806;
  text-transform: uppercase;
}

.title-angle-shape {
  display: inline-block;
  padding: 0 0 0 15px;
  position: relative;
}

.title-angle-shape::before {
  position: absolute;
  top: 0;
  content: "";
  background: #c50806;
  left: 0;
  width: 3px;
  height: 12px;
}

.title-angle-shape::after {
  position: absolute;
  top: 0;
  content: "";
  background: #c50806;
  left: 0;
  width: 12px;
  height: 3px;
}

.home-footer {
  background-color: #c3c3c3;
}

.home-footer-copyright {
  background-color: #fff;
}

.global-padding {
  padding: 10px 18.75px;
}

.footer-title {
  font-size: 28px;
  color: #c50806;
  font-weight: 700;
}

.footer-divider {
  background-color: #c50806 !important;
  width: 80%;
}

.footer-li {
  color: #000;
  font-size: 18px;
  line-height: 3rem;
}

.footer-li a {
  color: #000;
}

.footer-grille-tarifaire {
  margin-left: -0.5rem;
  margin-top: 20px;
  max-width: 288px;
}

.color-black {
  color: #000;
}

.no-bullet {
  list-style: none;
  padding-left: 20px;
}

.nav-image {
  max-width: 121px;
  max-height: 100px;
}

.nav-slide {
  padding: 15px 40px;
}

.radio-list-img {
  max-width: 60px;
}

.font-small {
  font-size: 1em !important;
  line-height: 1.2em;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 12px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.nav-slide .swiper-button-prev:after,
.nav-slide .swiper-button-next:after {
  font-size: 13px;
  border: 1px solid #6f6f6f;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: #6f6f6f;
  color: #fff;
}

.nav-slide .swiper-button-prev:after {
  font-size: 13px;
  border: 1px solid #6f6f6f;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: #6f6f6f;
  color: #fff;
}

.small-link-title {
  color: #333 !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.big-link-title {
  color: #333 !important;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.small-img {
  height: 155px;
  width: 100%;
}

.big-img {
  height: 409px;
  width: 100%;
}

.article-block {
  padding: 8px;
}

.news-item-wrapper {
  padding: 0;
}

.large-4.medium-12.columns.pt-1.news-item-wrapper:nth-child(3n + 2) {
  clear: both;
}

.embedded-news-item-wrapper {
  clear: both;
}

.tv-swiper-wrapper {
}

.tv-swiper-active {
  border: 2px solid #3789f0;
}

.tv-swiper-sub-wrapper {
  text-align: center;
  background: #fff;
  border-radius: 30px;
}

.radio-swiper-sub-wrapper {
  text-align: center;
  background: #fff;
  border-radius: 30px;
  padding: 1rem;
}

.tv-swiper-img {
  height: 100%;
  width: 180px;
}

.radio-swiper-img {
  height: 75px;
  width: 90px;
}

.width-100 {
  width: 100%;
}

.float-lg-right {
  float: right !important;
}

/* .article-block-img {
    max-height: 370px;
} */

@media (min-width: 992px) {
  .news-item-wrapper:nth-child(3n + 2) {
    clear: both;
  }
  .embedded-news-item-wrapper:nth-child(4n + 3) {
    clear: both;
  }
}

@media (max-width: 992px) {
  .nav-image {
    max-width: 100px;
    height: auto;
  }
  .news-item-wrapper:nth-child(2n + 1) {
    clear: both;
  }
  .float-left {
    float: left !important;
  }
}

@media (max-width: 767px) {
  .digiqole-widget-logo {
    text-align: center;
  }
  .nav-image {
    max-width: 80px;
    height: auto;
  }
}
