#top {
   background-color: #F7F7F7!important;
}
.m-icon {
    font-size: 20px!important;
    vertical-align: middle;
    margin-bottom: 2px;
}

.social-m-icon {
    font-size: 20px !important;
    margin-right: 1rem;
    vertical-align: middle;
}
.social-m-cal {
    color: #C50806;
    vertical-align: middle;
    margin-right: 0.5rem;
}
.m-font-big {
    font-size: 25px!important;
}
.calendar-text {
    font-size: 14px;
    color: #C50806;
    font-weight: 700;
}

.pt-1 {
    padding-top: 1rem;
}