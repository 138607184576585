.off-canvas-content,
.off-canvas-content {
  background: #ffffff;
}
body {
  font-family: "RTS1Police" !important;
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "RTS1Police" !important;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-CondensedOblique.eot');
  src: local('RTS1Police  Condensed Medium Oblique'), local('FuturaLT-CondensedOblique'),
      url('../fonts/RTS1/FuturaLT-CondensedOblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-CondensedOblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-CondensedOblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-CondensedOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Light.eot');
  src: local('RTS1Police  Light'), local('FuturaLT-Light'),
      url('../fonts/RTS1/FuturaLT-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Light.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Light.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-ExtraBold.eot');
  src: local('RTS1Police  Extra Bold'), local('FuturaLT-ExtraBold'),
      url('../fonts/RTS1/FuturaLT-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-ExtraBold.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-ExtraBold.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-ExtraBoldOblique.eot');
  src: local('RTS1Police  Extra Bold Oblique'), local('FuturaLT-ExtraBoldOblique'),
      url('../fonts/RTS1/FuturaLT-ExtraBoldOblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-ExtraBoldOblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-ExtraBoldOblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-ExtraBoldOblique.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-LightOblique.eot');
  src: local('RTS1Police  Light Oblique'), local('FuturaLT-LightOblique'),
      url('../fonts/RTS1/FuturaLT-LightOblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-LightOblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-LightOblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Condensed.eot');
  src: local('RTS1Police  Condensed Medium'), local('FuturaLT-Condensed'),
      url('../fonts/RTS1/FuturaLT-Condensed.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Condensed.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Condensed.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Condensed.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Condensed.eot');
  src: local('RTS1Police  Condensed Medium'), local('FuturaLT-Condensed'),
      url('../fonts/RTS1/FuturaLT-Condensed.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Condensed.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Condensed.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Condensed.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Condensed.eot');
  src: local('RTS1Police  Condensed Medium'), local('FuturaLT-Condensed'),
      url('../fonts/RTS1/FuturaLT-Condensed.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Condensed.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Condensed.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Condensed.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Book.eot');
  src: local('RTS1Police  Book'), local('FuturaLT-Book'),
      url('../fonts/RTS1/FuturaLT-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Book.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Book.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Oblique.eot');
  src: local('RTS1Police  Medium Oblique'), local('FuturaLT-Oblique'),
      url('../fonts/RTS1/FuturaLT-Oblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Oblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Oblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Oblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-CondensedExtraBold.eot');
  src: local('RTS1Police  Condensed Extra Bold'), local('FuturaLT-CondensedExtraBold'),
      url('../fonts/RTS1/FuturaLT-CondensedExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-CondensedExtraBold.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-CondensedExtraBold.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-CondensedExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT.eot');
  src: local('RTS1Police  Medium'), local('FuturaLT'),
      url('../fonts/RTS1/FuturaLT.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-CondensedLightObl.eot');
  src: local('RTS1Police  Condensed Light Oblique'), local('FuturaLT-CondensedLightObl'),
      url('../fonts/RTS1/FuturaLT-CondensedLightObl.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-CondensedLightObl.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-CondensedLightObl.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-CondensedLightObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-BookOblique.eot');
  src: local('RTS1Police  Book Oblique'), local('FuturaLT-BookOblique'),
      url('../fonts/RTS1/FuturaLT-BookOblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-BookOblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-BookOblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-BookOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-CondExtraBoldObl.eot');
  src: local('RTS1Police  Condensed Extra Bold Oblique'), local('FuturaLT-CondExtraBoldObl'),
      url('../fonts/RTS1/FuturaLT-CondExtraBoldObl.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-CondExtraBoldObl.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-CondExtraBoldObl.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-CondExtraBoldObl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-HeavyOblique.eot');
  src: local('RTS1Police  Heavy Oblique'), local('FuturaLT-HeavyOblique'),
      url('../fonts/RTS1/FuturaLT-HeavyOblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-HeavyOblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-HeavyOblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-HeavyOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-BoldOblique.eot');
  src: local('RTS1Police  Bold Oblique'), local('FuturaLT-BoldOblique'),
      url('../fonts/RTS1/FuturaLT-BoldOblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-BoldOblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-BoldOblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-CondensedBoldOblique.eot');
  src: local('RTS1Police  Condensed Bold Oblique'), local('FuturaLT-CondensedBoldOblique'),
      url('../fonts/RTS1/FuturaLT-CondensedBoldOblique.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-CondensedBoldOblique.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-CondensedBoldOblique.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-CondensedBoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-CondensedLight.eot');
  src: local('RTS1Police  Condensed Light'), local('FuturaLT-CondensedLight'),
      url('../fonts/RTS1/FuturaLT-CondensedLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-CondensedLight.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-CondensedLight.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-CondensedLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Bold.eot');
  src: local('RTS1Police  Bold'), local('FuturaLT-Bold'),
      url('../fonts/RTS1/FuturaLT-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Bold.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Bold.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-Heavy.eot');
  src: local('RTS1Police  Heavy'), local('FuturaLT-Heavy'),
      url('../fonts/RTS1/FuturaLT-Heavy.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-Heavy.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-Heavy.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'RTS1Police';
  src: url('../fonts/RTS1/FuturaLT-CondensedBold.eot');
  src: local('RTS1Police  Condensed Bold'), local('FuturaLT-CondensedBold'),
      url('../fonts/RTS1/FuturaLT-CondensedBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/RTS1/FuturaLT-CondensedBold.woff2') format('woff2'),
      url('../fonts/RTS1/FuturaLT-CondensedBold.woff') format('woff'),
      url('../fonts/RTS1/FuturaLT-CondensedBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}



.rts-wrapper .rts-title {
  color: #c50806;
  font-size: 14px;
  padding: 3px 6px;
  margin-right: 5px;
  font-weight: 500;
}
.rts-wrapper a {
  margin-right: 2px;
}

footer .widgetBox .widgetTitle {
  margin-bottom: 15px;
}
footer .widgetBox .widgetTitle h5 {
  color: #ffff00;
  font-size: 12px;
}
footer .widgetBox .widgetContent ul li a {
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  transition: 0.3s all ease;
}
footer .widgetBox .widgetContent ul li a:hover {
  color: #ffff00;
}
footer .widgetBox .social-link ul {
  display: flex;
  justify-content: space-between;
}
footer .widgetBox .social-link ul li a {
  padding-top: 1px;
  border: 1px solid #fff;
  border-radius: 50%;
  height: 25px !important;
  width: 25px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
footer .widgetBox .social-link ul li a:hover {
  color: inherit;
}
footer {
  padding: 30px 0 20px;
  /*  background-image: url(../images/red-bg-pattern.jpg);*/
  background-repeat: no-repeat;
}

.topBar {
  border-bottom: none;
  padding: 7px 0;
  background: #fff;
}
.topBar .socialLinks a,
.video-main .socialLinks a {
  display: inline-block;
  border-radius: 50% !important;
  border: 1px solid #444851;
  width: 26px;
  height: 25px;
  line-height: 24px;
  margin-right: 4px;
  text-align: center;
}
.topBar .socialLinks a .fa,
.video-main .socialLinks a .fa {
  color: #444851;
}
.topBar .row {
  display: flex;
  align-items: center;
}
.topBar .row .medium-8 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#navBar .top-bar .menu-text {
  padding: 2px 0 !important;
}
#navBar .top-bar .menu > li:not(.menu-text) > a {
  padding: 19px 0;
  font-size: 14px;
  font-weight: 500;
}
#navBar .top-bar .menu > li:first-child a .fa {
  font-size: 20px;
}
#navBar .top-bar .menu > li {
  padding-left: 40px;
}
#navBar .top-bar .menu > li:not(.menu-text) > a:hover {
  border-bottom: 2px solid #2c8f11;
}
#navBar .top-bar .menu > li:not(.menu-text) > a:hover i {
  color: #ffffff;
}
#navBar .top-bar .search-btn {
  padding: 10px 0;
}
#navBar .top-bar .search-btn ul li i {
  font-size: 18px !important;
  color: #fff;
  border-radius: 10px !important;
}

.menu-new-wrap {
  background: #fff;
  display: inline-block;
  padding: 0 10px;
  width: 86%;
}
.sticky.topnav {
  background-color: #fff;
  position: unset;
}

div#beNav {
  background-color: #fff;
}
.top-bar-left > ul {
  background-color: #fff;
}

#navBar .top-bar {
  display: flex;
}
.top-bar-left {
  float: none;
  width: 14%;
}
.topBar img {
  width: inherit;
}

section.latest-items img,
section.news-items img,
section.program-wrap img,
section.current-show img,
.broadcast-inner-wrap img,
.video-inner-section img {
  width: 100%;
  max-width: 100%;
}
.broadcast-inner-wrap img {
  width: 270px;
  height: 202px;
}
section.latest-items .columns {
  padding: 0;
}
section.latest-items .row {
  padding: 0 15px;
}
section.latest-items {
  margin-top: 25px;
}
/*.latest-items-right img{
    height: 220px;
}*/
.latest-items-right .li-right .play-btn img,
section.current-show .play-btn img {
  height: 60px;
  width: 60px;
}
.latest-items-right .li-right .li-data p {
  margin: 0;
  font-weight: 700;
  color: #c50705 !important;
}
.latest-items-right .li-right,
.latest-items-left .li-left {
  position: relative;
}
.latest-items-right .li-right .li-data,
.latest-items-left .li-left .li-data {
  position: absolute;
  bottom: 10px;
  padding: 0 20px;
}
.latest-items-right .li-right .play-btn,
.latest-items-left .li-left .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.latest-items-left .li-left .li-data p {
  font-size: 22px;
  margin: 0;
  font-weight: 700;
  color: #c50705 !important;
}

section.news-items h4,
.video-main .video-info h4 {
  font-weight: 900;
  line-height: 1.8;
  margin-bottom: 15px;
  font-size: 22px;
}
section.news-items .news-info p,
.video-main .video-info p {
  font-size: 20px;
  color: #333 !important;
  line-height: 1.8;
}
section.news-items .news-wrap {
  margin: 40px 0 0 0;
}
section.news-items .news-wrap .news-detail h5,
section.news-items .news-wrap .news-detail h6 {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
  margin-bottom: 8px;
}
section.news-items .news-wrap .news-detail p,
section.news-items .news-wrap .news-detail p span {
  color: #828282 !important;
  margin: 0;
  font-size: 12px !important;
  text-transform: uppercase;
}
h2.section-title {
  position: relative;
  padding-left: 26px;
  font-size: 36px;
}
h2.section-title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 14px;
  background-color: #c50705;
  height: 38px;
  display: inline-block;
  z-index: 9999;
}
.more-btn-wrap h2.more-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 24px;
  margin: 20px 0;
}
.more-btn-wrap h2.more-btn .fa {
  font-size: 22px;
  margin-right: 6px;
  padding-top: 5px;
}

section.program-wrap {
  margin: 40px 0;
}
.program-wrap .p-inner-wrap .p-inner-right h3 {
  margin: 0;
  font-size: 16px;
}
.program-wrap .p-inner-wrap {
  display: flex;
  border-bottom: 1px solid #c50705;
  margin-bottom: 10px;
}
.program-wrap .p-inner-wrap:last-child {
  border: none;
  margin-bottom: 8px;
}
.program-wrap .p-inner-right .p-data p {
  font-size: 12px;
  margin: 0;
}
.program-wrap .p-inner-right .p-data {
  display: flex;
  margin-bottom: 5px;
}
.program-wrap .p-inner-right .p-data .p-data-left {
  margin-right: 15px;
}
.program-wrap .p-inner-right .p-data-right img {
  display: inherit;
}
.program-wrap .p-inner-right .p-inner-right {
  margin-left: 20px;
}
.program-wrap .program-wrap-right h4 {
  font-weight: 900;
  font-size: 26px;
  margin-bottom: 10px;
}
.program-wrap .program-wrap-right {
  padding-left: 0;
}
.program-wrap .program-wrap-right .program-wrap-right-inner {
  padding: 10px 50px;
  border-radius: 30px;
  border: 1px solid #dedede;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.6);
}
.program-wrap .p-inner-right {
  margin-left: 15px;
}
.program-wrap .p-inner-left img {
  height: 80px;
}
.tab ul {
  display: flex;
  list-style: none;
  margin: 0 0 15px 0;
  justify-content: space-around;
}
.tab ul li {
  background-color: #f2e5e5;
  width: 100%;
  text-align: center;
  padding: 2px;
  transition: 0.5s all ease;
}
.tab ul li:nth-child(2) {
  border-left: 1px solid #c50705;
  border-right: 1px solid #c50705;
}
.tab ul li a {
  color: #000;
  font-weight: 500;
}
.tab ul li.active,
.tab ul li:hover {
  background-color: #fff;
  position: relative;
}
.tab ul li.active a,
.tab ul li:hover a {
  color: #c50705;
}
.tab ul li.active:after {
  content: "";
  position: absolute;
  border-left: 18px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #c50705;
  bottom: -8px;
  transform: translateX(-6px);
  left: 50%;
}
.program-wrap-left-inner,
.current-show-left,
.current-show-right .cs-right,
.video-main .video-main-inner {
  position: relative;
}
.program-wrap-left-inner .play-btn,
.current-show-left .play-btn,
.video-main .video-main-inner .play-btn {
  position: absolute;
  top: 75%;
  left: 85%;
  transform: translate(-50%, -50%);
}
.current-show-right .cs-right .play-btn {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.current-show-right .cs-right h3 {
  margin: 0;
  font-size: 13px;
  height: 58px;
  display: block;
  overflow: hidden;
}
.current-show-right .cs-right p {
  margin: 0;
  margin: 0;
  color: #c50705 !important;
  font-size: 12px;
}
.current-show .d-n-t {
  position: absolute;
  background-color: #c3bd57;
  background-color: rgba(212, 204, 70, 0.6);
  border-bottom-right-radius: 22px;
}
.current-show .current-show-left .d-n-t {
  border-bottom-right-radius: 37px;
}
.current-show-right .d-n-t p {
  margin: 0;
  font-size: 10px;
  padding: 7px 12px 7px 7px;
  color: #fff !important;
  border-bottom-right-radius: 8px;
}
.current-show-left .d-n-t p {
  margin: 0;
  font-size: 15px;
  padding: 10px;
  color: #fff !important;
  border-bottom-right-radius: 8px;
}
section.current-show .current-show-right .play-btn img {
  height: 28px;
  width: 28px;
}
.current-show-right .cs-right .cs-data {
  margin-top: 5px;
}
.current-show-right .cs-right {
  margin-bottom: 22px;
}
.broadcasts-wrap .column:last-child:not(:first-child),
.broadcasts-wrap .columns:last-child:not(:first-child) {
  float: none;
}
.broadcast-inner-wrap .b-data {
}
.broadcast-inner-wrap .b-data h3 {
  font-size: 20px;
  margin: 0;
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.broadcast-inner-wrap .b-data span {
  color: #333 !important;
  font-size: 15px;
}
.broadcast-inner-wrap .b-data p {
  color: #333 !important;
  font-size: 13px;
  height: 56px;
  display: block;
  overflow: hidden;
}

.news-items .post .post-des {
  padding: 0;
}
.news-wrap .column:last-child:not(:first-child),
.news-wrap .columns:last-child:not(:first-child) {
  float: left;
}

.new-article .program-wrap-right-inner {
  padding: 30px 15px !important;
}
.new-article .program-wrap-right-inner p-inner-wrap .p-inner-right h3 {
  margin: 0;
  color: #000000;
  font-size: 18px;
}
.new-article .program-wrap-right-inner .p-inner-wrap {
  align-items: center;
}
.new-article .program-wrap-right-inner .p-inner-wrap {
  border-bottom: 1px solid #c50705;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.new-article .program-wrap-right-inner .row .columns:last-child .p-inner-wrap {
  border: none;
  margin: 0;
}
.new-article-program-advertise {
  border-radius: 23px;
  overflow: hidden;
  margin: 50px 0;
}

.new-article .article-block a h4,
.new-article .article-block a h6 {
  color: #333 !important;
  margin: 0;
  line-height: initial;
}
.new-article .article-block a h4 {
  font-size: 16px;
  height: 20px;
  display: block;
  overflow: hidden;
}
.new-article .article-block a h6 {
  font-size: 12px;
  height: 28px;
  display: block;
  overflow: hidden;
}
.new-article .article-block p.d-n-t {
  margin-bottom: 0px;
  font-weight: 300;
}
.new-article .article-block.small p.d-n-t {
  font-size: 12px;
}
.new-article .article-block.big p.d-n-t {
  font-size: 14px;
}
.article-intro-wrap {
  margin-top: 20px;
}
.new-article .international .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 0px 10px 0px 33px;
  margin-bottom: 10px;
  position: relative;
}
.new-article .international .title:before {
  content: "";
  position: absolute;
  left: 0;
  width: 14px;
  height: 100%;
  background-color: #c50705;
}
.new-article .international .title h3 {
  color: #000;
  margin: 0;
  line-height: initial;
  font-size: 22px;
}
.new-article .international .title a {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.new-article .international {
  margin-bottom: 20px;
}

.video-title h2 {
  border-bottom: 4px solid #c50705;
  color: #000;
  margin-bottom: 25px;
}
.video-main .video-info .duration p {
  font-weight: 300;
  line-height: initial;
}
.video-main .video-info .video-author {
  display: flex;
  flex-wrap: wrap;
}
.video-main .video-info .video-author h3,
.video-main .video-info .video-author p,
.video-main .video-info .duration p {
  margin: 0;
  font-size: 24px;
}
.video-main .socialLinks a {
  width: 32px;
  height: 32px;
  line-height: 31px;
  margin-right: 1rem;
}
.video-main .socialLinks a .fa {
  font-size: 18px;
}

.video-inner-section .video-sub .columns {
  position: relative;
}
.video-inner-section .video-sub .play-btn {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-inner-section .video-sub .video-duration {
  position: absolute;
  top: 0;
}
.video-inner-section .video-sub .video-thumb {
  height: 151px;
  overflow: hidden;
}
.video-inner-section .video-sub .video-duration span {
  padding: 3px 20px;
  background-color: rgba(255, 255, 255, 0.6);
  color: #000 !important;
}
.video-inner-section .video-sub p {
  line-height: initial;
  font-weight: 500;
  color: #333 !important;
  height: 30px;
}
.video-inner-section .video-sub .columns:last-child:not(:first-child) {
  float: left;
}
.video-inner-section .video-main {
  margin-bottom: 50px;
}
.video-new-carousel .category-heading {
  padding: 0;
  border: none;
  margin: 0;
}
.video-new-carousel .secBg {
  border: none;
}
.video-new-carousel {
  margin: 30px 0;
}
.video-new-carousel .columns {
  position: relative;
}
.video-new-carousel .navText .prev {
  position: absolute;
  top: 35%;
  left: 36px;
  z-index: 999;
}
.video-new-carousel .navText .next {
  position: absolute;
  top: 35%;
  right: 34px;
  z-index: 999;
}

.video-new-carousel .owl-carousel {
  padding: 15px 75px;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.8);
  border-radius: 52px;
}
.video-new-carousel .navText .fa {
  font-size: 48px;
  font-weight: bold;
}
section.news-items .news-wrap .news-detail h6 a {
  height: 27px;
  display: block;
  overflow: hidden;
}
