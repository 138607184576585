

.news-wrapper {
    margin-top: 21px!important;
}

.charger {
    margin: 1.5rem 0 1rem!important;
}

.charger button {
    background-color: #D9D9D9;
    color: #000;
    font-size: 16px;
    padding: 0.1rem 2rem;
    text-transform: none;
}

.back-arrow {
    color: #000;
    cursor: pointer;
}

.detail-content {
    background: white;
    padding: 1rem;
    margin-top: 21px!important;
}

.detail-category-time {

}

.detail-category {
    font-size: 18px;
    font-weight: 500;
    color: #C50806;
}

.detail-time {
    margin-left: 2rem;
    font-size: 1rem;
    font-size: 15px;
}

.news-detail-timer-icon {
    vertical-align: middle;
    font-size: 15px!important;
}

.detail-image {
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    float: left;
    max-width: 60%;
}

.detail-description {
    font-size: 36px;
    padding-top: 0;
    /* line-height: 33px; */
    color: #000;
    font-weight: 400;
    font-size: 16px;
}

iframe {
    width: 100%;
}

.mb-2 {
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .detail-image {
        max-width: 100%;
    }
    iframe {
        height: auto;
    }
}