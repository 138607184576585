.radio-play-wrapper {
  padding: 2rem 3rem;
}
.radio-play-icon {
  font-size: 100px !important;
  color: #c50705;
  margin: 1rem 0;
  cursor: pointer;
}

.radio-play-title {
  margin-bottom: 1rem;
}

.radio-play-title > :first-child {
  color: #c50705;
  font-size: 20px;
  font-weight: 700;
}

.radio-play-desc {
  font-size: 16px;
  font-weight: normal;
  color: #000 !important;
}

#waveform wave {
  overflow: hidden !important;
}

@media (max-width: 767px) {
  .radio-play-wrapper {
    padding: 1.5rem 1.5rem;
  }
  .radio-play-desc {
    font-size: 14px;
  }
}
