.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.modal-body {
  position: relative;
  padding: 0px;
}
.close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}

.pagination > li > a {
  color: #971b25;
  border-color: #971b25;
}

.pagination > li > span {
  color: #971b25;
  border-color: #971b25;
}
.pagination > .active > a {
  color: #fff;
  background-color: #971b25;
  border-color: #971b25;
}
/* colors */
/* tab setting */
/* breakpoints */
/* selectors relative to radio inputs */
/* html {
  width: 100%;
  height: 100%;
}
body {
  background: #efefef;
  color: #333;
  font-family: "RTS1Police";
  height: 100%;
}
body h1 {
  text-align: center;
  color: #428bff;
  font-weight: 300;
  padding: 40px 0 20px 0;
  margin: 0;
} */
.tabs {
  left: 2%;
  position: relative;
  background: white;
  padding: 50px;
  height: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  min-width: 240px;
}
.tabs input[name="tab-control"] {
  display: none;
}
.tabs .content section h2,
.tabs ul li label {
  font-family: "RTS1Police";
  font-weight: bold;
  font-size: 18px;
  color: #428bff;
}
.tabs ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tabs ul li {
  box-sizing: border-box;
  flex: 1;
  width: 25%;
  padding: 0 10px;
  text-align: center;
}
.tabs ul li label {
  transition: all 0.3s ease-in-out;
  color: #929daf;
  padding: 5px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
}
.tabs ul li label br {
  display: none;
}
.tabs ul li label svg {
  fill: #929daf;
  height: 1.2em;
  vertical-align: bottom;
  margin-right: 0.2em;
  transition: all 0.2s ease-in-out;
}
.tabs ul li label:hover,
.tabs ul li label:focus,
.tabs ul li label:active {
  outline: 0;
  color: #bec5cf;
}
.tabs ul li label:hover svg,
.tabs ul li label:focus svg,
.tabs ul li label:active svg {
  fill: #bec5cf;
}
.tabs .slider {
  position: relative;
  width: 25%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
.tabs .slider .indicator {
  position: relative;
  width: 50px;
  max-width: 100%;
  margin: 0 auto;
  height: 4px;
  background: #428bff;
  border-radius: 1px;
}
.tabs .content {
  margin-top: 30px;
}
.tabs .content section {
  display: none;
  animation-name: content;
  animation-direction: normal;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  line-height: 1.4;
}
.tabs .content section h2 {
  color: #428bff;
  display: none;
}
.tabs .content section h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #428bff;
  margin-top: 5px;
  left: 1px;
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ ul
  > li:nth-child(1)
  > label {
  cursor: default;
  color: #428bff;
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ ul
  > li:nth-child(1)
  > label
  svg {
  fill: #428bff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(1):checked
    ~ ul
    > li:nth-child(1)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(1):checked ~ .slider {
  transform: translateX(0%);
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ .content
  > section:nth-child(1) {
  display: block;
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ ul
  > li:nth-child(2)
  > label {
  cursor: default;
  color: #428bff;
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ ul
  > li:nth-child(2)
  > label
  svg {
  fill: #428bff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(2):checked
    ~ ul
    > li:nth-child(2)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(2):checked ~ .slider {
  transform: translateX(100%);
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ .content
  > section:nth-child(2) {
  display: block;
}
.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ ul
  > li:nth-child(3)
  > label {
  cursor: default;
  color: #428bff;
}
.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ ul
  > li:nth-child(3)
  > label
  svg {
  fill: #428bff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(3):checked
    ~ ul
    > li:nth-child(3)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(3):checked ~ .slider {
  transform: translateX(200%);
}
.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ .content
  > section:nth-child(3) {
  display: block;
}
.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ ul
  > li:nth-child(4)
  > label {
  cursor: default;
  color: #428bff;
}
.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ ul
  > li:nth-child(4)
  > label
  svg {
  fill: #428bff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(4):checked
    ~ ul
    > li:nth-child(4)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(4):checked ~ .slider {
  transform: translateX(300%);
}
.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ .content
  > section:nth-child(4) {
  display: block;
}
@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
@media (max-width: 1000px) {
  .tabs ul li label {
    white-space: initial;
  }
  .tabs ul li label br {
    display: initial;
  }
  .tabs ul li label svg {
    height: 1.5em;
  }
}
@media (max-width: 600px) {
  .tabs ul li label {
    padding: 5px;
    border-radius: 5px;
  }
  .tabs ul li label span {
    display: none;
  }
  .tabs .slider {
    display: none;
  }
  .tabs .content {
    margin-top: 20px;
  }
  .tabs .content section h2 {
    display: block;
  }
}

.flash-info {
  background-color: #f7f7f7;
}
.flash-title {
  padding: 10px 18.75px;
}

.flash-desc {
  display: flex;
  align-items: center;
}

.flash-desc p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #000 !important;
}

.flash-swiper {
  padding: 0 2rem !important;
  width: 90%;
  color: #6b0100;
  font-size: 17px;
}

.flash-swiper .swiper-button-prev {
  left: 93% !important;
}

.flash-swiper .swiper-button-prev:after,
.flash-swiper .swiper-button-next:after {
  font-size: 13px;
  border: 1px solid #6f6f6f;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: #6f6f6f;
  color: #fff;
}

.flash-swiper .swiper-button-prev:after {
  font-size: 13px;
  border: 1px solid #6f6f6f;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: #6f6f6f;
  color: #fff;
}

.title-border {
  position: relative;
  margin: 10px 0;
}

.title-border h4 {
  font-size: 16px;
  color: #c50705;
  margin-bottom: 0;
}

.title-border::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: calc(100% - 120px);
  height: 1px;
  background: #000;
}

.spot-card {
  padding: 12px 2rem;
  margin-top: 21px;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.spot-card {
  width: 90%;
}

.section-mt {
  margin-top: 1.5rem;
}

.selection-img {
  height: 180px;
  width: 100%;
}

.program-img {
  height: 90px;
  width: 120px;
}

.radio-img {
  max-height: 120px;
  width: 100%;
}

.spot-card h5 {
  font-size: 20px;
  font-weight: 500;
  color: #c50705;
  text-align: center;
  background-color: #f7f7f7;
}

.card-info-detail {
  margin-bottom: 15px;
  padding-bottom: 0;
}

.card-info-time {
  font-size: 12px;
  color: #c50705 !important;
  margin-bottom: 0;
}

.card-info-text {
  font-size: 12px;
  font-weight: 500;
  color: #333 !important;
  margin-bottom: 0;
  position: relative;
  line-height: 15px;
  text-transform: uppercase;
}

.card-info-text::after {
  position: absolute;
  content: "";
  bottom: -3px;
  left: 0;
  margin: auto;
  width: 2rem;
  height: 1px;
  background: #c50705;
}

.show-more {
  text-align: center;
  padding-top: 10px !important;
}

.show-more button {
  background-color: #d7d7d7;
  font-size: 12px !important;
  color: #c50705;
  text-transform: none !important;
  font-weight: 600;
}

.plus-m-icon {
  font-size: 18px !important;
  margin-right: 10px;
  color: #c50705;
}

.grey-back {
  background-color: #f7f7f7;
  padding: 15px 20px;
}

.video-desc {
  display: flex;
  background: white;
  padding: 12px;
  align-items: center;
  min-height: 48px;
}

.video-desc h3 {
  font-size: 16px;
  margin-bottom: 0;
  color: #646464;
}

.video-title {
  width: 80%;
}

.video-play-button {
  width: 20%;
  text-align: right;
}

.video-play-button svg {
  vertical-align: middle;
  font-size: 42px;
}

.video-swiper {
  padding: 15px 25px !important;
}

.video-swiper .swiper-button-next:after {
  font-size: 20px;
  color: #706b6b;
  font-weight: 700;
}

.video-swiper .swiper-button-next {
  right: -5px !important;
}

.video-swiper .swiper-button-prev:after {
  font-size: 20px;
  color: #706b6b;
  font-weight: 700;
}

.video-swiper .swiper-button-prev {
  left: -5px !important;
}

.swiper-container {
  width: 480px;
}

.selection-title {
  font-size: 20px !important;
  font-weight: 500;
  text-transform: uppercase;
}

.w-100 {
  width: 100%;
}

.radio-title {
  font-size: 14px !important;
  color: #c50806 !important;
  text-align: center;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.email-sender {
  background-color: #000;
  padding-top: 12px;
  padding-bottom: 12px;
}

.email-box form {
  background-color: #303030;
}

.sender-text {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}

.sender-icon svg {
  color: #fff;
}

.emial-input {
  /* width: 80%; */
  background-color: #303030;
}

.emial-input input {
  box-shadow: unset;
  padding: 6px 12px;
  width: auto;
  background-color: #303030;
  color: white;
}

.emial-input input:focus {
  box-shadow: none;
  border: none;
  background-color: #303030;
}

.email-paper {
  background-color: #303030 !important;
}

.email-submit-btn {
  border-radius: 0 3px 0 3px !important;
  color: #c50705 !important;
  background-color: #fff !important;
  font-size: 19px !important;
  font-weight: bold !important;
  width: 100%;
  height: 100%;
}

.replay-img {
  max-width: 40px;
  height: auto;
}

.position-relative {
  position: relative;
}

.replay-image {
  max-width: 50px;
  height: auto;
  position: absolute;
  bottom: 80px;
  right: 10px;
}

.featured-thumbnail {
  width: 50%;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

@media (max-width: 1000px) {
  .flash-swiper .swiper-button-prev {
    left: 93% !important;
  }
}

@media (max-width: 1000px) {
  .flash-swiper .swiper-button-prev {
    left: 90% !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1000px) {
  .d-featured-none {
    display: none !important;
  }
  .featured-thumbnail {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .flash-swiper .swiper-button-prev {
    left: 88% !important;
    top: 69% !important;
  }
  .flash-swiper .swiper-button-next {
    top: 69% !important;
  }
  .flash-desc {
    display: block;
  }
  .flash-swiper {
    width: 100%;
    padding: 0 !important;
  }
  .spot-card {
    width: 100%;
  }
  .email-submit-btn {
    font-size: 14px !important;
  }
  .featured-thumbnail {
    width: 100%;
  }
  /* .sender-icon {
    font-size: 15px;
  } */
}

@media (max-width: 576px) {
  .flash-swiper .swiper-button-prev {
    left: 85% !important;
  }
}

@media (max-width: 400px) {
  .flash-swiper .swiper-button-prev {
    left: 75% !important;
  }
}
