#owl-demo .item {
  margin: 3px;
}
#owl-demo .item img {
  display: block;
  width: 100%;
  height: auto;
}

.pagination > li > a {
  color: #971b25;
  border-color: #971b25;
}

.pagination > li > span {
  color: #971b25;
  border-color: #971b25;
}
.pagination > .active > a {
  color: #fff;
  background-color: #971b25;
  border-color: #971b25;
}

.tv-list-img {
  max-width: 64px;
  display: inline-block;
  margin-left: -10px;
}

.tv-list-channel {
  display: inline-block;
  font-size: 24px !important;
  color: #c50705 !important;
}

.tv-list-desc {
  font-size: 17px !important;
  color: #000 !important;
}

.tv-list-title {
}

.tv-list-title-prefix {
  font-size: 20px;
  font-weight: 700;
  color: #c50705;
}

.tv-list-title-content {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}

.tv-list-title-divider {
  width: 70%;
  background-color: #000 !important;
  margin: 1rem 0 !important;
}

.tv-social-desc h3 {
  color: #c50705;
  font-size: 20px;
}

.page-title {
  font-size: 15px;
  font-weight: 700;
  color: #000;
}
