#pr
{
    background-color: darkcyan;
    border-bottom-right-radius: 20px;
    width: 150px;
}

#scd
{
    background-color: deeppink;
    border-bottom-right-radius: 20px;
    width: 150px;
}

#frs
{
    background-color: blueviolet;
    border-bottom-right-radius: 20px;
    width: 150px;
}
#fournav
{
    background-color: forestgreen;
    border-bottom-right-radius: 20px;
    width: 150px;
}
#five
{
    background-color: burlywood;
    border-bottom-right-radius: 20px;
    width: 150px;
}
#programme
{
    background-color: #c50705;
    border-bottom-right-radius: 10px;
    width: 350px;
    margin: 2px;
}

.vertical-timeline::before
{
    background-color: #c50705 !important;
    top: 0px !important;
}

.MuiTimelineConnector-root
{
    background-color: transparent !important;
}

#six
{
    background-color: darkgoldenrod;
    border-bottom-right-radius: 20px;
    width: 150px;
}

#sept
{
    background-color: blueviolet;    
    border-bottom-left-radius: 20px;
    width: 150px;
}

#calrow
{
    padding-bottom: 2px;
}

#calligne
{
    margin-right: 2px;
}

#datecomplet
{
    border-bottom-right-radius: 30px;
}

#icalen
{

}

#paper
{
    height: 80px;
    width: 300px;
    margin-left: auto;
}
#papersf
{
    height: 80px;
    width: 300px;
    margin-right: auto;
}

#matinbg{
    background-color: white;
    width: 120px;
}
#pguide
{
    text-align: center;
    padding-top: 2px;
    color: black;
}
#pguides
{
    text-align: center;
    color: black;
    font-size: 15px;
}

#idfour
{
    color: white;
}
#idcon
{
    color: #c50705;
}

.tmpline
{
    color: #c50705;
    background-color: #c50705;
}


/* a:active {
    background-color: #c50705;
} */

.borclass
{
    background-color: #c50705 !important;
}

.borclass1
{
    border: 5px solid #c50705;
}



.Schedule-header,
    h1 {
        color: #ffffff;
        background-color: #c50705;
        margin-top: 20px;
    }

    .Schedule-header .schedule-days {
        text-align: center;
    }

    .Schedule-header a {
        padding: 5px;
        text-align: center;
        background-color: #c50705;
        color: #ffffff;
        margin: 20px;
    }

    .schedule-days {
        list-style: none;
    }

    .schedule-days li {
        display: inline-block;
    }

    /* Survol */
    .Schedule-header a:hover {
        background-color: #ffffff;
        color: #c50705;
    }
    .schedule-content-value:hover {
        background-color: #faafaf;
    }
    a .lien-emission {
        /* background-color: #ffffff; */
        background-color: #c50705;
        /* color: #8A0000; */
    }

    /* Les contenus du schedules */
    .time-day,
    .programme {
        color: #333;
    }

    .schedule-content-day-state {
        margin-left: 150px;
    }

    .schedule-content-day-state h3 {
        margin-bottom: 50px;
        margin-top: 20px;
    }

    /* Style Buttom if it is current day */
    .schedule-days a.current {
        background-color: #ffffff;
        color: #c50705;
    }

    /* Home page etat du programme aujourd'hui */
    .programme-matin {
        background-color: #c50705;
        color: #ffffff;
    }
    .programme-matin:hover {
        background-color: #c50705;
        color: #ffffff;
    }
    .programme-soir:hover {
        background-color: #c50705;
        color: #ffffff;
    }








