
/*
	* Style settings of LayerSlider
	*
	* (c) 2011-2013 George Krupa, John Gera & Kreatura Media
	*
	* Plugin web:			http://kreaturamedia.com/
	* Licenses: 			http://codecanyon.net/licenses/
*/



/* Global settings */

.ls-container {
	visibility: hidden;
	position: relative;
}

.ls-lt-container {
	position: absolute;
}

.ls-lt-container,
.ls-lt-container * {
	text-align: left !important;
	direction: ltr !important;
}

.ls-container-fullscreen {
	margin: 0 auto !important;
	padding: 2% !important;
	background: black !important;
	border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-webkit-border-radius: 0 !important;
	border: none !important;
}

.ls-container-fullscreen .ls-thumbnail-wrapper,
.ls-container-fullscreen .ls-fullscreen,
.ls-container-fullscreen .ls-shadow {
	display: none !important;
}

.ls-overflow-hidden {
	overflow: hidden;
}

.ls-inner {
	position: relative;
	background-position: center center;
	z-index: 2;
}

.ls-loading-container {
	position: absolute !important;
	display: none;
	z-index: 3 !important;
	left: 50% !important;
	top: 50% !important;
}

.ls-loading-indicator {
	margin: 0 auto;
}

.ls-inner,
.ls-slide {
	width: 100%;
	height: 100%;
}

.ls-slide,
.ls-layer {
	position: absolute;
	display: none;
	background-position: center center;
	overflow: hidden;
}

.ls-active,
.ls-animating {
	display: block !important;
}

.ls-slide > * {
	position: absolute;
	line-height: normal;
	margin: 0;
	left: 0;
	top: 0;
}

.ls-slide .ls-bg {
	left: 0px;
	top: 0px;
	transform: none !important;
	-o-transform: none !important;
	-ms-transform: none !important;
	-moz-transform: none !important;
	-webkit-transform: none !important;
}

.ls-yourlogo {
	position: absolute;
	z-index: 99;
}



/* Timers */

.ls-bar-timer {
	position: absolute;
	width: 0;
	height: 2px;
	background: white;
	border-bottom: 2px solid #555;
	opacity: .55;
	filter: alpha(opacity=55);
	z-index: 4;
	top: 0;
}

.ls-circle-timer {
	width: 16px;
	height: 16px;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 4;
	opacity: .65;
	filter: alpha(opacity=65);
	display: none;
}

.ls-ct-half {
	background: white;
}

.ls-ct-center {
	background: #444;
}

.ls-ct-left,
.ls-ct-right {
	width: 50%;
	height: 100%;
	overflow: hidden;
}

.ls-ct-left,
.ls-ct-right {
	float: left;
	position: relative;
}

.ls-ct-rotate {
	width: 200%;
	height: 100%;
	position: absolute;
	top: 0;
}

.ls-ct-left .ls-ct-rotate,
.ls-ct-right .ls-ct-hider,
.ls-ct-right .ls-ct-half {
	left: 0;
}

.ls-ct-right .ls-ct-rotate,
.ls-ct-left .ls-ct-hider,
.ls-ct-left .ls-ct-half {
	right: 0;
}

.ls-ct-hider,
.ls-ct-half {
	position: absolute;
	top: 0;
}

.ls-ct-hider {
	width: 50%;
	height: 100%;
	overflow: hidden;
}

.ls-ct-half {
	width: 200%;
	height: 100%;
}

.ls-ct-center {
	width: 50%;
	height: 50%;
	left: 25%;
	top: 25%;
	position: absolute;
}

.ls-ct-half,
.ls-ct-center {
	border-radius: 100px;
	-moz-border-radius: 100px;
	-webkit-border-radius: 100px;
}



/* Navigation */

.ls-bottom-nav-wrapper {
	height: 0;
}

.ls-bottom-slidebuttons {
	text-align: left;
}

.ls-bottom-nav-wrapper,
.ls-below-thumbnails {
	z-index: 2;
	height: 0;
	position: relative;
	text-align: center;
	margin: 0 auto;
}

.ls-below-thumbnails {
	display: none;
	z-index: 6;
}

.ls-bottom-nav-wrapper a,
.ls-nav-prev,
.ls-nav-next {
	outline: none;
}

* .ls-bottom-nav-wrapper *,
* .ls-bottom-nav-wrapper span * {
	direction: ltr !important;
}

.ls-bottom-slidebuttons {
	position: relative;
	z-index: 1000;
}

.ls-bottom-slidebuttons,
.ls-nav-start,
.ls-nav-stop,
.ls-nav-sides {
	position: relative;
}

.ls-nothumb {
	text-align: center !important;
}

.ls-link {
	position: absolute;
	width: 100% !important;
	height: 100% !important;
	left: 0 !important;
	top: 0 !important;
	background-image: url(blank.gif);
}

.ls-slide > a > * {
	background-image: url(blank.gif);
}



/* Embedded videos */

.ls-vpcontainer {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.ls-videopreview {
	width : 100%;
	height : 100%;
	position : absolute;
	left : 0;
	top : 0;
	cursor : pointer;
}

.ls-playvideo {
	position: absolute;
	left: 50%;
	top: 50%;
	cursor: pointer;
}



/* Thumbnails */

.ls-tn {
	display: none !important;
}

.ls-thumbnail-hover {
	display: none;
	position: absolute;
	left: 0;
}

.ls-thumbnail-hover-inner {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: none;
}

.ls-thumbnail-hover-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.ls-thumbnail-hover-img {
	position: absolute;
	overflow: hidden;
}

.ls-thumbnail-hover img {
	max-width: none !important;
	position: absolute;
	display: inline-block;
	visibility: visible !important;
	left: 50%;
	top: 0;
}

.ls-thumbnail-hover span {
	left: 50%;
	top: 100%;
	width: 0;
	height: 0;
	display: block;
	position: absolute;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
	border-bottom-color: transparent !important;
}

.ls-thumbnail-wrapper {
	position: relative;
	width: 100%;
	margin: 0 auto;
	z-index: 4;
}

.ls-thumbnail {
	position: relative;
	margin: 0 auto;
}

.ls-thumbnail-inner,
.ls-thumbnail-slide-container {
	width: 100%;
}

.ls-thumbnail-slide-container {
	overflow: hidden !important;
	position: relative;
}

.ls-touchscroll {
	overflow-x: auto !important;
}

.ls-thumbnail-slide {
	text-align: center;
	white-space: nowrap;
	float: left;
	position: relative;
}

.ls-thumbnail-slide a {
	overflow: hidden;
	display: inline-block;
	width: 0;
	height: 0;
	position: relative;
}

.ls-thumbnail-slide img {
	max-width: none !important;
	max-height: 100% !important;
	height: 100%;
	visibility: visible !important;
}

.ls-shadow {
	display: none;
	position: absolute;
	z-index: 1;
	top: 100%;
	width: 100%;
	left: 0;
	overflow: hidden !important;
	visibility: hidden;
}

.ls-shadow img {
	width: 100% !important;
	height: auto !important;
	position: absolute !important;
	left: 0 !important;
	bottom: 0 !important;
}

.ls-bottom-nav-wrapper,
.ls-thumbnail-wrapper,
.ls-nav-prev,
.ls-nav-next {
	visibility: hidden;
}



/* WP plugin fullwidth */

.ls-wp-fullwidth-container {
	width: 100%;
	position: relative;
}

.ls-wp-fullwidth-helper {
	position: absolute;
}



/* 2D & 3D Layer Transitions */

.ls-overflow-hidden {
	overflow: hidden;
}

.ls-lt-tile {
	position: relative;
	float: left;
	perspective: 1000px;
	-o-perspective: 1000px;
	-ms-perspective: 1000px;
	-moz-perspective: 1000px;
	-webkit-perspective: 1000px;
}

.ls-lt-tile img {
	visibility: visible;
	display: inline-block;
}

.ls-curtiles {
	overflow: hidden;
}

.ls-curtiles,
.ls-nexttiles {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.ls-curtile, .ls-nexttile {
	overflow: hidden;
	position: absolute;
	width: 100% !important;
	height: 100% !important;
	backface-visibility: hidden;
	-o-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.ls-curtile {
	left: 0;
	top: 0;
}

.ls-curtile img,
.ls-nexttile img {
	position: absolute;
	filter: inherit;
}

.ls-3d-container {
	position: relative;
	overflow: visible !important;
}

.ls-3d-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
}

.ls-3d-box div {
	overflow: hidden;
	background: #777;
	margin: 0;
	padding: 0;
	position: absolute;
}



/* Full screen */

.ls-fullscreen {
	position: absolute;
	z-index: 10;
	cursor: pointer;
	display: block;
}



/* Removing all default global styles of WordPress themes */

html * .ls-nav-prev,
html * .ls-nav-next,
html * .ls-container img,
html * .ls-bottom-nav-wrapper a,
html * .ls-container .ls-fullscreen,
body * .ls-nav-prev,
body * .ls-nav-next,
body * .ls-container img,
body * .ls-bottom-nav-wrapper a,
body * .ls-container .ls-fullscreen,
#ls-global * .ls-nav-prev,
#ls-global * .ls-nav-next,
#ls-global * .ls-container img,
#ls-global * .ls-bottom-nav-wrapper a,
#ls-global * .ls-container .ls-fullscreen,
html * .ls-thumbnail a,
body * .ls-thumbnail a,
#ls-global * .ls-thumbnail a {
	transition: none;
	-o-transition: none;
	-ms-transition: none;
	-moz-transition: none;
	-webkit-transition: none;
	line-height: normal;
	outline: none;
	padding: 0;
	border: 0;
}

html * .ls-slide > a,
body * .ls-slide > a,
#ls-global * .ls-slide > a,
html * .ls-slide > h1,
body * .ls-slide > h1,
#ls-global * .ls-slide > h1,
html * .ls-slide > h2,
body * .ls-slide > h2,
#ls-global * .ls-slide > h2,
html * .ls-slide > h3,
body * .ls-slide > h3,
#ls-global * .ls-slide > h3,
html * .ls-slide > h4,
body * .ls-slide > h4,
#ls-global * .ls-slide > h4,
html * .ls-slide > h5,
body * .ls-slide > h5,
#ls-global * .ls-slide > h5,
html * .ls-slide > p,
body * .ls-slide > p,
#ls-global * .ls-slide > p,
html * .ls-slide > div,
body * .ls-slide > div,
#ls-global * .ls-slide > div,
html * .ls-slide > span,
body * .ls-slide > span,
#ls-global * .ls-slide > span,
html * .ls-slide > *,
body * .ls-slide > *,
#ls-global * .ls-slide > * {
	transition: none;
	-o-transition: none;
	-ms-transition: none;
	-moz-transition: none;
	-webkit-transition: none;
}

html * .ls-slide > *,
body * .ls-slide > *,
#ls-global * .ls-slide > * {
	margin: 0;
}

html * .ls-container img,
body * .ls-container img,
#ls-global * .ls-container img {
	background: none !important;
	min-width: 0 !important;
	max-width: none !important;
	border-radius: 0;
	box-shadow: none;
	border: 0;
	padding: 0;
}

/*html * .ls-thumbnail a img,
body * .ls-thumbnail a img,
#ls-global * .ls-thumbnail a img {
	min-width: 100% !important;
}
*/

html * .ls-wp-container .ls-slide > *,
body * .ls-wp-container .ls-slide > *,
#ls-global * .ls-wp-container .ls-slide > * {
	line-height: normal;
	outline: none;
	padding: 0;
	margin: 0;
	border: 0;
}

html * .ls-wp-container .ls-slide > a > *,
body * .ls-wp-container .ls-slide > a > *,
#ls-global * .ls-wp-container .ls-slide > a > * {
	margin: 0;
}

html * .ls-wp-container .ls-slide > a,
body * .ls-wp-container .ls-slide > a,
#ls-global * .ls-wp-container .ls-slide > a {
	text-decoration: none;
}

.ls-wp-fullwidth-container,
.ls-wp-fullwidth-helper,
.ls-container,
.ls-container * {
	box-sizing: content-box !important;
	-moz-box-sizing: content-box !important;
	-webkit-box-sizing: content-box !important;
}

html * .ls-yourlogo,
body * .ls-yourlogo,
#ls-global * .ls-yourlogo {
	margin: 0;
}

html * .ls-tn,
body * .ls-tn,
#ls-global * .ls-tn {
	display: none;
}

.site {
	overflow: visible !important;
}



/* Style of LayerSlider Debug Console */

.ls-debug-console * {
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	color: white !important;
	text-shadow: none !important;
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", Helvetica, Arial, sans-serif !important;
	line-height: normal !important;
	-webkit-font-smoothing: antialiased !important;
	text-align: left !important;
	font-style: normal !important;
}

.ls-debug-console h1 {
	padding-top: 10px !important;
	font-size: 17px !important;
	font-weight: bold !important;
}

.ls-debug-console h1:first-child {
	padding-top: 0 !important;
}

.ls-debug-console ul {
	padding-top: 10px !important;
	list-style: none !important;
}

.ls-debug-console li {
	margin-left: 10px !important;
	font-size: 13px !important;
	position: relative !important;
	font-weight: normal !important;
}

html * .ls-debug-console li ul,
body * .ls-debug-console li ul,
#ls-global * .ls-debug-console li ul {
	display: none;
	width: 260px;
	left: -10px;
}

.ls-debug-console li ul {
	position: absolute !important;
	bottom: 100% !important;
	padding: 10px 10px 10px 0 !important;
	background: white !important;
	border-radius: 10px !important;
	box-shadow: 0 0 20px black !important;
}

html * .ls-debug-console li:hover ul,
body * .ls-debug-console li:hover ul,
#ls-global * .ls-debug-console li:hover ul {
	display: block;
}

.ls-debug-console li ul * {
	color: black !important;
}

.ls-debug-console a {
	text-decoration: none !important;
	border-bottom: 1px dotted white !important;
}

.ls-error {
	border-radius: 5px !important;
	-moz-border-radius: 5px !important;
	-wenkit-border-radius: 5px !important;
	background: white !important;
	height: auto !important;
	width: auto !important;
	color: white !important;
	padding: 20px 40px 30px 80px !important;
	position: relative !important;
	box-shadow: 0 2px 20px -5px black;
}

.ls-error p {
	line-height: normal !important;
	text-shadow: none !important;
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	text-align: justify !important;
	font-family: Arial, sans-serif !important;
}

.ls-error .ls-error-title {
	line-height: 40px !important;
	color: red !important;
	font-weight: bold !important;
	font-size: 16px !important;
}

.ls-error .ls-error-text {
	color: #555 !important;
	font-weight: normal !important;
	font-size: 13px !important;
}

.ls-error .ls-exclam {
	width: 40px !important;
	height: 40px !important;
	position: absolute !important;
	left: 20px !important;
	top: 20px !important;
	border-radius: 50px !important;
	-moz-border-radius: 50px !important;
	-webkit-border-radius: 50px !important;
	font-size: 30px !important;
	font-weight: bold !important;
	color: white !important;
	line-height: 40px !important;
	background: red !important;
	text-align: center !important;
}



/* GPU Hardware Acceleration */

html * .ls-container .ls-shadow,
html * .ls-container .ls-slide > *,
html * .ls-container .ls-fullscreen,
html * .ls-container .ls-3d-container,
html * .ls-container .ls-lt-container,
html * .ls-container .ls-lt-container *,
html * .ls-container .ls-thumbnail-wrapper,
html * .ls-container .ls-bottom-nav-wrapper,
body * .ls-container .ls-shadow,
body * .ls-container .ls-slide > *,
body * .ls-container .ls-fullscreen,
body * .ls-container .ls-3d-container,
body * .ls-container .ls-lt-container,
body * .ls-container .ls-lt-container *,
body * .ls-container .ls-thumbnail-wrapper,
body * .ls-container .ls-bottom-nav-wrapper,
#ls-global * .ls-container .ls-shadow,
#ls-global * .ls-container .ls-slide > *,
#ls-global * .ls-container .ls-fullscreen,
#ls-global * .ls-container .ls-3d-container,
#ls-global * .ls-container .ls-lt-container,
#ls-global * .ls-container .ls-lt-container *,
#ls-global * .ls-container .ls-thumbnail-wrapper,
#ls-global * .ls-container .ls-bottom-nav-wrapper,
.ls-gpuhack {
	transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}

.ls-gpuhack {
	width: 100% !important;
	height: 100% !important;
}


html * .ls-container .ls-webkit-hack,
body * .ls-container .ls-webkit-hack,
#ls-global * .ls-container .ls-webkit-hack {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}

/* GPU */

@media (transform-3d), (-o-transform-3d), (-ms-transform-3d), (-moz-transform-3d), (-webkit-transform-3d) {
	#ls-test3d {
		position: absolute;
		left: 9px;
		height: 3px;
	}
}
